import i18n from "@/plugins/i18n";
import store from "@/plugins/store/store";
import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const routes = [
  {
    path: "/login",
    name: "login",
    component: () => import(/* webpackMode: "lazy" */ "@/views/Auth/Login"),
    meta: {
      title: "login",
    },
  },
  {
    path: "/logout",
    name: "logout",
    component: () => import(/* webpackMode: "lazy" */ "@/views/Auth/Logout"),
    meta: {
      title: "logout",
    },
  },
  {
    path: "/password/recover",
    name: "password-recover",
    component: () => import(/* webpackMode: "lazy" */ "@/views/Auth/PasswordRecover"),
    meta: {
      title: "password-recover",
    },
  },
  {
    path: "/password/recover/:id",
    component: () => import(/* webpackMode: "lazy" */ "@/views/Auth/PasswordChange"),
    meta: {
      title: "password-recover",
    },
  },
  {
    path: "/",
    component: () => import("@/views/Base"),
    meta: {
      title: "welcome",
    },
    children: [
      {
        path: "",
        name: "dashboard",
        component: () => import(/* webpackMode: "lazy" */ "@/views/Home"),
        meta: {
          title: "welcome",
          auth: true,
        },
      },
      {
        path: "",
        name: "bookings",
        component: () => import(/* webpackMode: "lazy" */ "@/views/Bookings/History"),
        meta: {
          title: "bookings",
          auth: true,
        },
      },
    ],
  },
  {
    path: "*",
    component: () => import(/* webpackMode: "lazy" */ "@/views/errors/Error404"),
    meta: {
      title: "not_found",
    },
  },
  //End
];

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: routes,
  scrollBehavior() {
    window.scrollTo({ top: 0, left: 0 });
  },
});

router.beforeEach(async (to, from, next) => {
  store.dispatch("ENABLE_LOADING");

  const token = store.getters["auth/authToken"];
  const storedAuth = store.getters["auth/isLoggedIn"];
  if (to.name === "login" && token) {
    next({ name: "dashboard" });
  } else if (to.matched.some((record) => record.meta.auth)) {
    if (token && !storedAuth) {
      await store.dispatch("auth/deleteAuthCookies", null, { root: true });
      await next({ name: "login", params: { redirectTo: to.fullPath } });
    } else if (to.name !== "login" && !token) {
      next({
        name: "login",
        params: { redirectTo: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

router.afterEach((to) => {
  document.title = `${to.meta ? `${i18n.t(to.meta.title ? to.meta.title : to.name)} | ` : ""
    } Emdidoc`;
});

export default router;
