import i18n from '@/plugins/i18n'
import store from '@/plugins/store/store'
import axios from 'axios'
import qs from 'qs'
import Vue from 'vue'

const apiRequest = axios.create({
  baseURL: process.env.VUE_APP_API_ROOT,
  timeout: 20000,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
});

apiRequest.interceptors.request.use(
  config => {
    config.headers.common['Accept-Language'] = i18n.locale;
    const token = Vue.$cookies.get('authToken');
    if (token) {
      config.headers.common.Authorization = `Bearer ${token}`
    }
    if (!store.state.isLoading && store.state.useLoading) {
      store.commit('SET_LOADING', true)
    }
    return config
  },
  error => {
    setTimeout(() => {
      store.commit('SET_LOADING', false)
    }, 500)
    return Promise.reject(error)
  },
);

apiRequest.interceptors.response.use(
  (response) => {
    setTimeout(() => {
      store.commit('SET_LOADING', false)
    }, 500)
    return response
  },
  async (error) => {
    setTimeout(() => {
      store.commit('SET_LOADING', false)
    }, 1000)

    if (!error.response) {
      return new Promise((resolve, reject) => {
        reject(error)
      })
    }

    // Return any error which is not due to authentication back to the calling service
    if (error.response.status !== 401) {
      return new Promise((resolve, reject) => {
        reject(error)
      })
    }

    if (error.response.status === 401) {
      store.dispatch('auth/deleteAuthCookies')
      document.location.href = ''
      return Promise.reject(error)
    }
  },
);

apiRequest.interceptors.request.use(config => {
  config.paramsSerializer = params => {
    return qs.stringify(params, {
      arrayFormat: 'brackets',
      encode: true,
    })
  };

  return config
});

export default apiRequest
