import payments from "../../../requests/payments";

// initial state
const state = () => ({
  bookings: [],
})

// getters
const getters = {
  bookings: state => state.bookings,
}

// mutations
const mutations = {
  setBookings: (state, bookings) => {
    state.bookings = bookings
  },

  deleteBookings: (state) => {
    state.bookings = null
  },
}

// actions
const actions = {
  loadBookings: async ({ commit }, payload) => {
    try {
      const response = await payments.load(payload)
      commit('setBookings', response.data)

      return Promise.resolve(response)
    } catch (error) {
      console.error('Error while getting bookings info', error)
      return Promise.reject(error.response)
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
