import api from "@/plugins/api";

export const payments = {
  load: async function(filters) {
    try {
      console.log(filters);
      const response = await api.post("/book/all", filters);
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  create: async function(data) {
    try {
      const response = await api.post("/book/add", data);
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  update: async function(data) {
    try {
      const response = await api.post("/book/update", data);
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  destroy: async function(id) {
    try {
      const response = await api.post("/book/remove", { id: id });
      return response.data;
    } catch (e) {
      console.error(e);
      throw e;
    }
  },
  downloadDocument: async function(item) {
    try {
      const response = await api.get(`/book/${item.code}/pdf`, {
        headers: {
          Accept: "application/pdf"
        },
        responseType: "blob"
      });
      const url = window.URL.createObjectURL(response.data);
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = `${item.name} ${item.last_name} (${item.passport}).pdf`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    } catch (e) {
      console.error(e);
      throw e;
    }
  }
};

export default payments;
