import i18n from "../../i18n"

const state = () => ({
  messages: [],
})

const getters = {
  messages: state => state.messages,
}

const mutations = {
  addMessage: (state, payload) => {
    let message = payload.message ?? '';
    for (const m in payload.messages) {
      message += ` ${payload.messages[m].replace(/[ .]*$/, '')}.`
    }
    state.messages.push({ message: message, type: payload.type, visible: true })
  },
  removeMessage: (state, position) => {
    state.messages.splice(position, 1)
  },
}

const actions = {
  info: ({ commit }, message) => {
    commit('addMessage', { message: message, type: 'info' })
  },
  warning: ({ commit }, message) => {
    commit('addMessage', { message: message, type: 'warning' })
  },
  success: ({ commit }, message) => {
    commit('addMessage', { message: message, type: 'success' })
  },
  error: ({ commit }, message) => {
    commit('addMessage', { message: message, type: 'error' })
  },
  remove: ({ commit }, position) => {
    commit('removeMessage', position)
  },
  errorResponse: async ({ commit }, error) => {
    if (!error || error.statusText && error.statusText === 'timeout') {
      await commit('addMessage', { message: i18n.t('connection_issues'), messages: [], type: 'error' })
    } else if (error.response || error.data) {
      const response = error.data ? error.data : error.response.data
      if (error.response && Array.isArray(response) && response.length == 0) {
        if (error.response.status == 404) {
          await commit('addMessage', { message: i18n.t('not-found-response'), messages: [], type: 'error' })
        }
      } else if (response) {
        const messages = [];
        for (const m in response.errors ?? []) {
          messages.push(`${response.errors[m]}`);
        }
        await commit('addMessage', { message: response.message ?? '', messages: messages ?? [], type: 'error' })
      }
    }
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}
